* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-image: url("../src/data/images/ContactUsBackground.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
